
<template>
  <div class="body add-vehicle">
    <el-form
      :model="addForm"
      :rules="rules"
      :inline="true"
      ref="addForm"
      label-width="82px"
      label-position="left"
      class="demo-form-inline dialog-form"
    >
      <!-- 选择车辆： -->
      <el-form-item label="选择车辆：" prop="vehicleNo">
        <car-tree @getData="getVehIds" :choosedCph="choosedCph" label="vehicleNo" :isEdit="isEdit"></car-tree>
      </el-form-item>
      <el-form-item label="司机姓名：" prop="credentialsNo">
        <el-select
          v-model="addForm.credentialsNo"
          :filter-method="vehicleMethodFun"
          filterable
          :disabled="disabledShow"
          placeholder="请选择司机"
        >
          <el-option
            v-for="item in driversOptions"
            :key="item.credentialsNo"
            :label="item.driverName"
            :value="item.credentialsNo"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="报备原因：" prop="informWhy">
        <el-select v-model="addForm.informWhy" clearable placeholder="请选择报备原因" :disabled="disabledShow">
          <el-option
            v-for="it in modelList"
            :label="it.dictValue"
            :value="it.dictCode"
            :key="it.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否上报：" prop="informAlarm">
        <el-select v-model="addForm.informAlarm" clearable placeholder="请选择是否上报" :disabled="disabledShow">
          <el-option
            v-for="i in informList"
            :label="i.dictValue"
            :value="i.dictCode"
            :key="i.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- 开始时间： -->
      <el-form-item prop="startTime" label="开始时间：">
        <el-date-picker
          v-model="addForm.startTime"
          type="datetime"
          format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择日期时间"
          :clearable="false"
          :picker-options="pickerOptions"
          :disabled="disabledShow"
        ></el-date-picker>
      </el-form-item>
      <!-- 结束时间： -->
      <el-form-item prop="endTime" label="结束时间：">
        <el-date-picker
          v-model="addForm.endTime"
          type="datetime"
          placeholder="选择日期时间"
          :clearable="false"
          format="yyyy-MM-dd HH:mm:ss"
          :picker-options="pickerOptions"
          :disabled="disabledShow"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="报备报警：" prop="informAlarmContent">
        <el-checkbox-group v-model="addForm.informAlarmContent" :disabled="disabledShow">
          <el-checkbox label="疲劳"></el-checkbox>
          <el-checkbox label="超速"></el-checkbox>
          <el-checkbox label="夜间行驶"></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item>
        <p style="margin-left:-20px;opacity:0.7;">(报警只能针对平台报警不产生)</p>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import carTree from '@/components/carTree/carSelectTree.vue'
import { formatDate, checkTimeEqualLimit, getStartDate, getCurentDate } from '@/common/utils/index'

import { insetAlarmInform, updateAlarmInformById, getAlarmInformById } from '@/api/lib/gps-api.js'
import { queryDictsByCodes } from '@/api/lib/api.js'
import { queryDriverByName } from '@/api/lib/bus-api.js';
export default {
  components: {
    carTree
  },
  props: {
    itemId: {
      type: [Number, String]
    },
    disabledShow: {
      type: Boolean,
      default: false
    }
  },
  data () {
    let validateDateTime1 = (rule, value, callback) => {
      if (!checkTimeEqualLimit(value, this.addForm.endTime)) {
        callback(new Error('开始时间必须小于结束时间'))
      } else {
        callback()
      }
    }
    let validateDateTime2 = (rule, value, callback) => {
      if (!checkTimeEqualLimit(this.addForm.startTime, value)) {
        callback(new Error('开始时间必须小于结束时间'))
      } else {
        callback()
      }
    }
    return {
      choosedCph: '',
      isEdit: false,
      addForm: {
        vehicleNo: null,
        startTime: new Date(getStartDate()),
        endTime: new Date(getCurentDate()),
        informWhy: null,
        informAlarm: null,
        informAlarmContent: []
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick (picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '昨天',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3400 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周前',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3400 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }
        ]
      },
      rules: {
        vehicleNo:[{ required: true, message: '请选择车辆', trigger: 'change' }],
        informAlarmContent: [
          { required: true, message: '请选择报警报备', trigger: 'blur' }
        ],
        startTime: [
          { required: true, message: '请选择', trigger: 'blur' },
          { validator: validateDateTime1, trigger: 'blur' }
        ],
        endTime: [
          { required: true, message: '请选择', trigger: 'blur' },
          { validator: validateDateTime2, trigger: 'blur' }
        ]
      },
      modelList: [],
      informList: [
        { dictValue: '是', dictCode: '1' },
        { dictValue: '否', dictCode: '0' }
      ],
      driversOptions: [],
    }
  },
  methods: {
    //模糊查询司机
    vehicleMethodFun (text) {
      queryDriverByName({ driverName: text }).then(res => {
        if (res.code == 1000) {
          this.driversOptions = res.data;
        }
      });
    },
    // 获取车辆数据
    getVehIds (val) {
      this.$refs.addForm.clearValidate()
      this.addForm.vehicleNo = val
    },
    // 新增/修改
    onSave () {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.$emit('onLoading', true)
          const data = {
            ...this.addForm
          }
          const mapToTrans = {
            fatigue: "疲劳",
            speed: "超速",
            night: "夜间行驶",
          }
          const obj = {
            fatigue: 0,
            speed: 0,
            night: 0,
          }
          data.startTime = formatDate(this.addForm.startTime)
          data.endTime = formatDate(this.addForm.endTime)
          data.informAlarmContent.map(item => {
            for (let i in mapToTrans) {
              if (mapToTrans[i] == item) {
                obj[i] = 1;
              }
            }
          })
          data.informAlarmContent = JSON.stringify(obj);
          delete data.time
          if (!this.isEdit) {
            insetAlarmInform(data).then((res) => {
              if (res.code === 1000) {
                this.$message.success('新增成功')
                this.clsoeDia(res.code === 1000)
              } else {
                this.$message.error(res.msg)
                this.$emit('onLoading', false)
              }
            })
          } else {
            data.id = this.addForm.id
            updateAlarmInformById(data).then((res) => {
              if (res.code === 1000) {
                this.$message.success('修改成功')
                this.clsoeDia(res.code === 1000)
              } else {
                this.$message.error(res.msg)
                this.$emit('onLoading', false)
              }
            })
          }
        } else {
          return false
        }
      })
    },
    /** 取消 */
    clsoeDia (flag) {
      this.$emit('close', flag)
    },
    getDicts () {
      queryDictsByCodes({ parentCodes: 'BBYY' }).then((res) => {
        if (res.code === 1000) {
          this.modelList = res.data.BBYY
        }
      })
    }
  },
  created () {
    this.getDicts()
  },
  mounted () {
    if (this.itemId) {
      const mapToTrans = {
        fatigue: "疲劳",
        speed: "超速",
        night: "夜间行驶",
      }
      getAlarmInformById({ id: this.itemId }).then((res) => {
        if (res.code === 1000) {
          this.addForm = { ...res.data }
          this.addForm.startTime = new Date(res.data.startTime)
          this.addForm.endTime = new Date(res.data.endTime)
          this.choosedCph = res.data.cph
         if(this.addForm.informAlarm !==null) this.addForm.informAlarm = res.data.informAlarm + ''
          this.addForm.informWhy = res.data.informWhy + ''
          const value = JSON.parse(res.data.informAlarmContent)
          let map = []
          for (let i in value) {
            if (value[i]) {
              map.push(mapToTrans[i])
            }
          }
          this.addForm.informAlarmContent = map
          this.vehicleMethodFun(res.data.driverName);
        } else {
          this.$message.error(res.msg)
        }
      })
      this.isEdit = true
    } else {
      this.isEdit = false
    }
  },
  watch: {
    cph (curVal, oldVal) {
      this.choosedCph = this.cph
    }
  }
}
</script>

<style lang="scss" scoped>
.add-vehicle {
  @include themify() {
    .form-title {
      font-size: 14px;
      color: themed('n7');
      margin-bottom: 10px;
    }
    .form-title2 {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
    }
  }
}
.el-form--inline .el-form-item {
  margin-right: 40px;
}
</style>
