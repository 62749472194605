<template>
  <div class="tourism-statistics" ref="tourismStatistics">
    <div class="function-buttons" ref="functionButtons">
      <el-button
        type="primary"
        size="small"
        @click="onAddTour"
        v-if="$store.state.menu.nowMenuList.indexOf('新增') >= 0"
      >新增</el-button>
    </div>
    <div class="form-area" ref="form">
      <el-form
        :model="form"
        ref="formInline"
        :rules="formRules"
        class="demo-form-inline"
        label-position="left"
        label-width="82px"
      >
        <el-form-item label="选择企业：">
          <companySelectForSearch
            ref="companySelectForSearch"
            :searchable="true"
            @getValue="getGroupIds"
          />
        </el-form-item>
        <el-form-item label="选择车辆：">
          <car-tree @getData="getVehIds" ref="carTree" label="vehicleNo"></car-tree>
        </el-form-item>
        <!-- 选择时间： -->
        <el-form-item label="选择时间：" prop="time" style="margin-right: 20px">
          <el-date-picker
            cellClassName="timeRangePicker"
            v-model="form.time"
            type="daterange"
            align="right"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            value-format="timestamp"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="报备原因：">
          <el-select v-model="form.informWhy" clearable placeholder="请选择报备原因">
            <el-option
              v-for="item in modelList"
              :label="item.dictValue"
              :value="item.dictCode"
              :key="item.dictCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="0">
          <el-button type="primary" size="small" :loading="searchLoading" @click="onSearch()">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="tableData" stripe :height="tableHeight">
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column prop="cph" label="车牌号" width="120" :show-overflow-tooltip="true"></el-table-column>

      <el-table-column prop="startTime" label="开始时间" width="170" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="endTime" label="结束时间" width="170" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="informAlarmContent" label="报备报警" width="170" :show-overflow-tooltip="true">
        <template slot-scope="scope">{{ informAlarmContentFun(scope.row.informAlarmContent) }}</template>
      </el-table-column>
      <el-table-column prop="informWhy" label="报备原因" :show-overflow-tooltip="true">
        <template slot-scope="scope">{{ modelObj[Number(scope.row.informWhy)] }}</template>
      </el-table-column>
      <el-table-column prop="informAlarm" label="是否报批">
        <template slot-scope="scope">{{scope.row.informAlarm === 1?'是':'否'}}</template>
      </el-table-column>
      <el-table-column prop="userName" label="记录人"></el-table-column>
      <el-table-column prop="userName" label="报备时间"></el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button @click="onViewTour(scope.row)" type="text" size="small" sort="primary">查看</el-button>
          <el-button
            @click="onUpdateTour(scope.row)"
            type="text"
            size="small"
            v-if="$store.state.menu.nowMenuList.indexOf('编辑') >= 0"
            sort="primary"
          >编辑</el-button>
          <el-button
            type="text"
            size="small"
            sort="danger"
            v-if="$store.state.menu.nowMenuList.indexOf('删除') >= 0"
            @click="onDeleteTour(scope.row)"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination" ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
    <!--新增 -->
    <el-dialog :title="title" v-if="dialogVisible" :visible.sync="dialogVisible" width="50%">
      <add-tour
        ref="addTour"
        :itemId="itemId"
        :parentCode="form.parentCode"
        @close="onDialogClose"
        @onLoading="onLoading"
        :disabledShow="disabledShow"
      ></add-tour>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dialogVisible = false">取消</el-button>
        <el-button
          type="primary"
          size="small"
          @click="onSave()"
          :loading="editLoading"
          v-show="title.indexOf('详情') === -1"
        >保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import carTree from '@/components/carTree/carSearchTree.vue'
import companySelectForSearch from '@/components/treeSelect/companySelectForSearch.vue'

import {
  formatDict,
  formatDate,
  formatDay,
  checkTimeLimitDay,
  getRangeDay
} from '@/common/utils/index'
import { queryDictsByCodes } from '@/api/lib/api.js'
import { delAlarmInformById, queryPageByQuery } from '@/api/lib/gps-api.js'

import addTour from './components/addTour.vue'

export default {
  name: 'tourismStatistics',
  components: { addTour, carTree, companySelectForSearch },
  data () {
    const validateDate1 = (rule, value, callback) => {
      if (value) {
        if (!checkTimeLimitDay(value[0], value[1], this.timeLimit)) {
          callback(new Error(`只能查询${this.timeLimit}天内的数据`))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    return {
      tableHeight: 0,
      total: 0,
      tableData: [],
      timeLimit: 60,
      form: {
        time: null,
        companyId: null,
        informWhy: null,
        vehicleNos: null,
        currentPage: 1,
        pageSize: 10
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick (picker) {
              const end = getRangeDay()[1]
              const start = getRangeDay()[1] - 3600 * 1000 * 24 * 7
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick (picker) {
              const end = getRangeDay()[1]
              const start = getRangeDay()[1] - 3600 * 1000 * 24 * 30
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      formRules: {
        time: [{ validator: validateDate1, trigger: 'change' }]
      },
      itemId: null,
      title: '',
      modelList: [],
      modelObj: [],
      dialogVisible: false,
      searchLoading: false,
      editLoading: false,
      disabledShow:false,//查看时所有的禁用掉
    }
  },
  methods: {
    // 计算表格高度
    computeHeight () {
      const wholeHeight = this.$refs.tourismStatistics.clientHeight
      const buttonsHeight = this.$refs.functionButtons.clientHeight
      const formHeight = this.$refs.form.clientHeight
      const paginationHeight = this.$refs.pagination.clientHeight
      this.tableHeight =
        wholeHeight -
        16 -
        buttonsHeight -
        16 -
        formHeight -
        paginationHeight -
        10 -
        16
    },
    // 点击查询
    onSearch () {
      this.searchLoading = true
      this.form.currentPage = 1
      this.form.pageSize = 10
      this.getListByField()
    },
    // 获取车组数据
    getGroupIds (val) {
      this.form.companyId = val[0];
    },
    // 获取车辆数据
    getVehIds (val) {
      this.form.vehicleNos = [...val]
    },
    //获取数据
    getListByField () {
      this.$refs.formInline.validate((valid) => {
        if (valid) {
          let data = { ...this.form }
          if (data.time) {
            data.startTime = formatDate(this.form.time[0])
            data.endTime =
              formatDate(this.form.time[1]).split(' ')[0] + ' 23:59:59'
          } else {
            data.startTime = null
            data.endTime = null
          }
          delete data.time
          queryPageByQuery(data)
            .then((res) => {
              if (res.code === 1000) {
                this.tableData = res.data.list
                this.total = res.data.total
                if (res.data.list.length === 0 && data.currentPage !== 1) {
                  this.form.currentPage -= 1
                  this.getListByField()
                }
              } else {
                this.tableData = []
                this.total = 0
                this.$message.error(res.msg)
              }
              this.searchLoading = false
            })
            .catch(() => {
              this.searchLoading = false
            })
        } else {
          this.searchLoading = false
        }
      })
    },
    // 当前页码改变
    onCurrentChange (page) {
      this.form.currentPage = page
      this.getListByField()
    },

    // 当前条数改变
    onSizeChange (size) {
      this.form.pageSize = size
      this.getListByField()
    },
    // 新增
    onAddTour () {
      this.title = '新增报备'
      this.itemId = null
      this.dialogVisible = true
      this.disabledShow = false;
    },

    // 查看
    onViewTour (row) {
      this.title = '报备详情'
      this.itemId = row.id
      this.dialogVisible = true
      this.disabledShow = true;
    },
    // 编辑
    onUpdateTour (row) {
      this.title = '编辑报备'
      this.itemId = row.id
      this.dialogVisible = true
      this.disabledShow = false;
    },
    //  保存
    onSave () {
      this.$refs.addTour.onSave()
    },
    onLoading (flag) {
      this.editLoading = flag
    },
    // 弹窗关闭时的回调
    onDialogClose (flag) {
      this.dialogVisible = false
      if (flag) {
        if (this.title.indexOf('新增') > -1) {
          this.onSearch()
        } else {
          this.getListByField()
        }
      }
      this.editLoading = false
    },
    //  删除
    onDeleteTour (row) {
      const h = this.$createElement
      this.$msgbox({
        title: '消息',
        type: 'warning',
        message: h('p', null, [
          h('span', null, '确定删除报备 '),
          h('span', null, ' ？')
        ]),
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          delAlarmInformById({id:row.id}).then((res) => {
            if (res.code === 1000) {
              this.$message.success('删除成功')
              this.getListByField()
            } else {
              this.$message.error(res.msg)
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            showClose: true,
            message: '已取消删除'
          })
        })
    },

    //获取字典值
    getDicts () {
      queryDictsByCodes({ parentCodes: 'BBYY' }).then((res) => {
        if (res.code === 1000) {
          this.modelList = res.data.BBYY
          this.modelObj = formatDict(this.modelList)
        }
      })
    }
  },
  computed:{
    informAlarmContentFun(){
      const mapToTrans = {
        fatigue: "疲劳",
        speed: "超速",
        night: "夜间行驶",
      }
     return value=>{
      value = JSON.parse(value)
        let map = []
          for (let i in value) {
            if (value[i]) {
              map.push(mapToTrans[i])
            }
          }
          return map.join("、")
      }
    }
  },
  created () {
    this.getDicts()
  },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight()
      this.onSearch()
      window.addEventListener('resize', this.computeHeight, false)
    })
  },
  destroyed () {
    window.removeEventListener('resize', this.computeHeight)
  }
}
</script>

<style lang="scss" scoped>
.tourism-statistics {
  height: 100%;
  width: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.theme-project-gps {
  .tourism-statistics {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
