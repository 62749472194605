var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "body add-vehicle" },
    [
      _c(
        "el-form",
        {
          ref: "addForm",
          staticClass: "demo-form-inline dialog-form",
          attrs: {
            model: _vm.addForm,
            rules: _vm.rules,
            inline: true,
            "label-width": "82px",
            "label-position": "left"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "选择车辆：", prop: "vehicleNo" } },
            [
              _c("car-tree", {
                attrs: {
                  choosedCph: _vm.choosedCph,
                  label: "vehicleNo",
                  isEdit: _vm.isEdit
                },
                on: { getData: _vm.getVehIds }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "司机姓名：", prop: "credentialsNo" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    "filter-method": _vm.vehicleMethodFun,
                    filterable: "",
                    disabled: _vm.disabledShow,
                    placeholder: "请选择司机"
                  },
                  model: {
                    value: _vm.addForm.credentialsNo,
                    callback: function($$v) {
                      _vm.$set(_vm.addForm, "credentialsNo", $$v)
                    },
                    expression: "addForm.credentialsNo"
                  }
                },
                _vm._l(_vm.driversOptions, function(item) {
                  return _c("el-option", {
                    key: item.credentialsNo,
                    attrs: { label: item.driverName, value: item.credentialsNo }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "报备原因：", prop: "informWhy" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    placeholder: "请选择报备原因",
                    disabled: _vm.disabledShow
                  },
                  model: {
                    value: _vm.addForm.informWhy,
                    callback: function($$v) {
                      _vm.$set(_vm.addForm, "informWhy", $$v)
                    },
                    expression: "addForm.informWhy"
                  }
                },
                _vm._l(_vm.modelList, function(it) {
                  return _c("el-option", {
                    key: it.dictCode,
                    attrs: { label: it.dictValue, value: it.dictCode }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否上报：", prop: "informAlarm" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    placeholder: "请选择是否上报",
                    disabled: _vm.disabledShow
                  },
                  model: {
                    value: _vm.addForm.informAlarm,
                    callback: function($$v) {
                      _vm.$set(_vm.addForm, "informAlarm", $$v)
                    },
                    expression: "addForm.informAlarm"
                  }
                },
                _vm._l(_vm.informList, function(i) {
                  return _c("el-option", {
                    key: i.dictCode,
                    attrs: { label: i.dictValue, value: i.dictCode }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "startTime", label: "开始时间：" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "datetime",
                  format: "yyyy-MM-dd HH:mm:ss",
                  placeholder: "选择日期时间",
                  clearable: false,
                  "picker-options": _vm.pickerOptions,
                  disabled: _vm.disabledShow
                },
                model: {
                  value: _vm.addForm.startTime,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "startTime", $$v)
                  },
                  expression: "addForm.startTime"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "endTime", label: "结束时间：" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "datetime",
                  placeholder: "选择日期时间",
                  clearable: false,
                  format: "yyyy-MM-dd HH:mm:ss",
                  "picker-options": _vm.pickerOptions,
                  disabled: _vm.disabledShow
                },
                model: {
                  value: _vm.addForm.endTime,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "endTime", $$v)
                  },
                  expression: "addForm.endTime"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "报备报警：", prop: "informAlarmContent" } },
            [
              _c(
                "el-checkbox-group",
                {
                  attrs: { disabled: _vm.disabledShow },
                  model: {
                    value: _vm.addForm.informAlarmContent,
                    callback: function($$v) {
                      _vm.$set(_vm.addForm, "informAlarmContent", $$v)
                    },
                    expression: "addForm.informAlarmContent"
                  }
                },
                [
                  _c("el-checkbox", { attrs: { label: "疲劳" } }),
                  _c("el-checkbox", { attrs: { label: "超速" } }),
                  _c("el-checkbox", { attrs: { label: "夜间行驶" } })
                ],
                1
              )
            ],
            1
          ),
          _c("el-form-item", [
            _c(
              "p",
              { staticStyle: { "margin-left": "-20px", opacity: "0.7" } },
              [_vm._v("(报警只能针对平台报警不产生)")]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }